import React,{useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { SessionStore } from '../services/SessionStore';


export const Logout = ( ) => {
  const {t} = useTranslation();
  
  useEffect( () => {    
    Object.keys( SessionStore.store ).filter( chunks => {
      return !chunks.includes( 'oidc.user' )
    } ).forEach( ( key ) => {
      SessionStore.remove( key )
    } )    
  },[] )
  
  return (
    <h4>
      {t( 'labels.logoutMessage' )}
    </h4> 
  );
};