import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FeatureFlags } from '../../data/Constants';
import { IClaimsData } from '../../../types';
import { ConfigurationApi } from '../../api/ConfigurationApi';
import { AppState } from '../AppStore';
import { setClaimsData } from '../../services/ConfigurationDataHelperFunctions';


const defaultClaimsData = {
  claims: null,
  featureFlags: FeatureFlags
} as IClaimsData

export const getClaims = createAsyncThunk( 'claimsData/getClaims', async ( action:{ token: string } )=>{
  return ConfigurationApi.getClaims( action )
} );

const claimsDataSlice = createSlice( {
  name: 'claimsData',
  initialState:defaultClaimsData,
  reducers:{},
  extraReducers:( builder ) =>{
    builder.addCase( getClaims.fulfilled, ( state, action ) => {
      if( action.payload.claims ) {
        const data = setClaimsData( action.payload, state );
        state.claims = data.claims;
        state.featureFlags = data.featureFlags;
      }
    } );
  }
} );

export const claimsData = ( state: AppState ) => state

export default claimsDataSlice.reducer
