import { Button, Select, SelectChangeEvent,InputLabel,FormControl,MenuItem,FormHelperText} from '@mui/material';
import React, { useEffect, useContext, useState}from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IConfigurationAction } from '../../types';
import { SessionStore } from '../services/SessionStore';
import { getLanguageCode, UrlHelper } from '../services';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, AppState } from '../store';
import { AppAuthContext } from '../services/Contexts';
import { ESessionStore, EUrlParams } from '../data/Constants';
import { resetError } from '../store/states/ErrorSlice';
import { loadConfiguration, validViewId } from '../store/states/ConfigurationSlice';

function handleViewParameter( viewIds:string[],setViewId:( value: string ) => void ) {
  if( viewIds && viewIds.length > 0 ) {
    setViewId( viewIds[0] )
    SessionStore.set( EUrlParams.ViewId,viewIds[0] );
  }
}

function invalidViewMessage( view:string[],t:( arg:string,arg2?:object ) => string ) {
  return view && view.length ? t( 'errorMessages.viewId.invalid',{view} ) : t( 'errorMessages.viewId.invalidNoView' )
}

function validViewMessage( configuration:IConfigurationAction,t:( arg:string,arg2?:object ) => string ) {
  return configuration.viewIds && configuration.viewIds.length === 1 ? t( 'errorMessages.viewId.viewOk' ) : t( 'errorMessages.viewId.viewSelect' )
}

export const ViewIdError = ( { view,handleContact }: any ) => {
  const dispatch = useDispatch<AppDispatch>();
  const configuration = useSelector( ( state: AppState )=> state.configuration )

  const navigate = useNavigate();
  const token = useContext( AppAuthContext );
  const {t} = useTranslation();
  const viewList:string[] = configuration.viewIds;
  const configurationId = UrlHelper.getSearchParameter( EUrlParams.ConfigurationId ) || SessionStore.get( ESessionStore.ConfigurationId ); 
  const [viewId,setViewId] = useState( '' )

  const onHandleView = ( event: SelectChangeEvent<string | number> ) => {
    SessionStore.set( ESessionStore.ViewId, event.target.value.toString() );
    setViewId( event.target.value.toString() )
  }
  
  useEffect( () => {
    handleViewParameter( configuration.viewIds,setViewId )
  } ,[] );

  const selectView = async ()=>{
    await dispatch( loadConfiguration( { configurationId: configurationId, languageCode: getLanguageCode(), token: token, viewId: SessionStore.get( ESessionStore.ViewId ) } ) );
    const params = UrlHelper.deleteSearchParameter( ESessionStore.ViewId );
    navigate( {search: params}, {replace:true} );
    SessionStore.set( ESessionStore.ViewId,SessionStore.get( ESessionStore.ViewId ) );
    dispatch( resetError() );
    dispatch( validViewId() )
  } 
  
  // To display error dialog when viewId is empty array
  if( !configuration.viewIds || configuration.viewIds.length === 0 ) {
    return <>
      <div>{t( 'errorMessages.viewId.noViews' )}</div>
      <br/>
      <Button className = "close-dialog" onClick={ () => handleContact( navigate, dispatch ) }>
        <span>{t( 'button.contactUS' )}</span>                
      </Button>
      <Button onClick={ dispatch( resetError() ) } className="btn-ok">
        <span>{t( 'button.ok' )}</span>
      </Button>
    </>
  }

  return <>
    {configuration.viewIds && configuration.viewIds.length === 0 ? <span>{t( 'errorMessages.viewId.noView' )}</span> :
      <>
        <span >
          {invalidViewMessage( view,t )}
          <br />
          {validViewMessage( configuration,t )}
        </span>
        {configuration.viewIds && configuration.viewIds.length > 0 && <>
          <FormControl fullWidth className="viewError" >
            <InputLabel>{t( 'userPreference.view' )}</InputLabel>
            {configuration.viewIds.length > 1 && 
        <Select color="primary" label="View Id" value={ viewId } onChange={ onHandleView } inputProps={ { readOnly:configuration.viewIds.length === 1 } }>
          {viewList.map( value => <MenuItem value={ value } key={ value } >{value.split( '_' )[1]}</MenuItem> )}
        </Select>}
            { configuration.viewIds.length === 1 && <>
              <Select color="primary" label="View Id" value={ SessionStore.get( ESessionStore.ViewId ) } onChange={ onHandleView } inputProps={ { readOnly:configuration.viewIds.length === 1 } }>
                {viewList.map( value => <MenuItem value={ value } key={ value } >{value.split( '_' )[1]}</MenuItem> )}
              </Select>
              <FormHelperText>{t( 'errorMessages.viewId.oneView' )}</FormHelperText></> }
          </FormControl>
        </>
        }
      </>
    }
    <br/>
    <Button className = "close-dialog" onClick={ () => handleContact( navigate, dispatch ) }>
      <span>{t( 'button.contactUS' )}</span>                
    </Button>
    <Button onClick={ selectView } className="btn-ok" data-testid="btn-ok">
      <span>{t( 'button.ok' )}</span>
    </Button>
  </>
};