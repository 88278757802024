import React from 'react';
import { Grid } from '@mui/material';
import { ISectionLayoutProps } from '../../types';
import { Section } from '.';
import { useTranslation } from 'react-i18next';
import { PhilipsGray } from '../../style/StyleHelpers';
import { isFeatureVisible } from '../services/ClaimsHelper';
import { useSelector } from 'react-redux';
import { AppState } from '../store';

/**
 * Creates and Renders the grid layout required for placing various input controls
 * @param {ISectionProps} props the properties for the section component
 * @returns {JSX.Element} the section component
 */

export const gridLayout = {
  xs: {
    input: 10,
    infoIcon: 2,
    optional: 6,
    price: 6
  },
  sm: {
    input: 7,
    infoIcon: 1,
    optional: 2,
    price: 2
  },
  fullWidth: 12
}

export const SectionLayout = ({ onChange, section, onOptionalChange, isDefaultFlow }: ISectionLayoutProps) => {
  const { t } = useTranslation();

  const claimsData = useSelector((state: AppState) => state.claimsData)

  const optionalCheck = isFeatureVisible(claimsData?.featureFlags?.Optional);
  const listPriceCheck = isFeatureVisible(claimsData?.featureFlags?.ListPrice);
  const showAsterisk = !isDefaultFlow ? ' *' : '';
  return <Grid container columns={gridLayout.fullWidth} height="100%">
    <Grid container item sm={gridLayout.fullWidth} display={{ xs: 'none', sm: 'flex', background: PhilipsGray, alignContent: 'center', height: 'fit-content' }} className="sectionLayout">
      <Grid item sm={gridLayout.sm.input} />
      <Grid item sm={gridLayout.sm.infoIcon} />
      <Grid item sm={gridLayout.sm.optional}>
        {optionalCheck ?
          <h6 className="sectionLayoutHeader">
            {t('labels.optional')}
          </h6> : null}
      </Grid>
      <Grid item sm={gridLayout.sm.price}>
        {listPriceCheck ?
          <h6 className="sectionLayoutHeader priceHeader text-right">
            {`${t('labels.listPrice')}${showAsterisk}`}
          </h6> : null}
      </Grid>
    </Grid>
    <Grid container item xs={gridLayout.fullWidth} width="100%" height={{ xs: '100%', sm: '90%' }} overflow="auto">
      <Section section={section} onChange={onChange} onOptionalChange={onOptionalChange} />
    </Grid>
  </Grid>
}