import React from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material"
import WarningIcon from '@mui/icons-material/Warning';
import DraggableComponent from "./DraggableComponent"
import { EActionType } from "../data/Constants"
import { useTranslation } from "react-i18next";

const ActionDialog = ({ showDialog, onCloseDialog, header, errorMessage, dialog, action, dialogType }: any) => {
    const { t } = useTranslation();

    const getDialogContent = () => {
        return errorMessage.length > 0 ? errorMessage : dialog;
    }

    return showDialog &&
        <Dialog open onClose={onCloseDialog} PaperComponent={DraggableComponent} aria-labelledby="draggable-dialog-title" className="common-dialog-style error-dialog-style cursor-move">
            <DialogTitle className="header title">
                <Grid container alignItems="center">
                    <><WarningIcon className="warningIcon" /> &nbsp;</>
                    <span > {header}</span>
                </Grid>
            </DialogTitle>
            <DialogContent className="action-panel-dialog-content">
                <DialogContentText>
                    {getDialogContent()}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {dialogType !== EActionType.Success && dialogType !== EActionType.IncompleteConfiguration ? <>
                    <Button
                        className="text-capitalize CloseDialog"
                        onClick={onCloseDialog}
                        data-testid="CloseDialog"
                    >
                        {t('button.cancel')}
                    </Button>
                    {errorMessage.length === 0 &&
                        <Button className="text-uppecase CallAction" onClick={action} data-testid="CallAction">
                            {t('button.ok')}
                        </Button>
                    }
                </> : <Button className="text-uppecase CallAction" onClick={onCloseDialog}>
                    {t('button.ok')}
                </Button>
                }
            </DialogActions>
        </Dialog>
}

export default ActionDialog;