import { Info } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { IInfoDialogIconProps } from '../../../types';
import { hasOrderingInstruction } from '../../services';
import { setInfoDialogVariable } from '../../store/states/ConfigurationSlice';

export const InfoDialogIcon = ( {variable,type}:IInfoDialogIconProps ) => {
  const dispatch = useDispatch<AppDispatch>();
  const getClassName = ()=>{
    let iconClass = ''
    let iconClassHighlight = ''
    iconClass = type && type === 'FF' ? 'familyInfoDialogIcon' : 'infoDialogIcon';
    const hasHighLight = hasOrderingInstruction( variable.properties ? variable.properties : [] ) || variable?.hasORI ;
    iconClassHighlight = hasHighLight ? 'infoDialogIconHighlight' : '';
    return iconClass + ' ' + iconClassHighlight
  }
  
  return <IconButton data-testid="btn-info-dialog" onClick={ () => dispatch( setInfoDialogVariable( {infoDialogVariable: variable} ) ) } className={ `${getClassName( )} ` }>
    <Info color="primary" />
  </IconButton> || null;
}