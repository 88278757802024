import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '../AppStore';

const initialState = false

const busySlice = createSlice( {
  name: 'busy',
  initialState,
  reducers:{
    setBusy( ) {
      return true;
    },
    resetBusy( ) {
      return false;
    }
  }
} )

export const { setBusy, resetBusy } = busySlice.actions

export const busy = ( state: AppState ) => state

export default busySlice.reducer
