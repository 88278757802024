import React from "react";
import { useTranslation } from "react-i18next";
import { isFeatureActive } from "../services/ClaimsHelper";
import { IFeatureFlags } from "../../types";

const Disclaimer = ({ featureFlags }: { featureFlags: IFeatureFlags }) => {
    const { t } = useTranslation();
    const showDisclaimer = isFeatureActive(featureFlags.ListPrice) && isFeatureActive(featureFlags.PriceDisclaimer)
    return (<>
        {showDisclaimer && <div className="disclaimer">
            <p>
                {t('disclaimer.promotionalMessage')}
            </p>
        </div>}
    </>)
}

export default Disclaimer;