import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Renders an error message for unauthorized users
 * @returns {JSX.Element} the error message component
 */
export const Unauthorized = ( ) => {
  const {t} = useTranslation()
  return (
    <h4>
      {t( 'errorMessages.general.403' )}
    </h4> 
  );
};