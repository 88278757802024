
import { Box, Table, TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../store';
import { IConfigurationVariable, ISummaryVariableProps } from '../../types';
import { getFormattedPrice, getValueFromVariable, getSummaryVariableName,getSymbol } from '../services';
import { EVariableType } from '../data/Constants';
import { isFeatureVisible } from '../services/ClaimsHelper';
import { isValidCurrency } from '../services/Price';

function showPrice( price:number,variable:IConfigurationVariable,valueBundled?:boolean ) {
  if( price === null ) {
    return false;
  }
  if( price >= 0 ) {
    return true;
  }
  return valueBundled || !( variable.variableType === EVariableType.Singleselect || variable.variableType === EVariableType.Multiselect ) && variable.isBundled;
}

/**
 * Renders the variable name and price on assigned value.
 * @param {ISummaryVariableProps} props the properties for the variable summary component
 * @returns {JSX.Element} the variable summary component
 */
export const VariableSummary = ( { variable, isScopeVariable,showOptional }: ISummaryVariableProps ) => {
  const userSettings = useSelector( ( state: AppState )=> state.userSettings )
  const claimsData = useSelector( ( state: AppState )=> state.claimsData )
  const applicationSettings = useSelector( ( state: AppState )=> state.applicationSettings )

  if( !userSettings ) {
    return null;
  }
  //removing optional values when show optional is disabled
  const tempVar = {...variable,values:variable.values.filter( ( v )=>showOptional || !v.isMarkedOptional )}
  const values = getValueFromVariable( tempVar, userSettings );
  const currency = applicationSettings?.currency;
  //getting the name as expected to be displayed in the summary panel
  const variableName = getSummaryVariableName( tempVar, userSettings );
  //getting price only for non scope variables
  if( values.length === 0 || tempVar.isMarkedOptional && !showOptional ) {
    return null;
  }
  return <Box className="variableSummary root" >
    <Box className="section">
      <span className="content" color="text.secondary" >
        {variableName}
      </span>
    </Box>
    <Table size="small" aria-label="Variable Summary Table">
      <TableBody> 
        {values.length > 0 && values.map( ( ele )=> <TableRow key={ ele?.name || '' + ele.price }>
          <TableCell component="th" className={ `variableName ${showOptional ? 'optionalValue' : undefined}` } scope="row">
            {ele.name}
          </TableCell>
          {isFeatureVisible( claimsData.featureFlags.ListPrice ) && showPrice( ele.price,variable,ele.isBundled ) && !isScopeVariable && <TableCell className="tableCell" align="right">
            <span className={ !isValidCurrency( currency?.currencyCode ) ? 'invalidCurrency' : 'content' } color="text.secondary">
              {currency?.currencyCode && getSymbol( currency?.countryCode, currency?.currencyCode ) }
            </span>
            <span className="content" color="text.secondary" >
              {applicationSettings?.currency.currencyCode && getFormattedPrice ( ele.price )}
            </span>
          </TableCell>
          }
        </TableRow> )}
      </TableBody>
    </Table>
  </Box>;
}