import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DefaultLanguage } from '../../data/Constants';
import { SessionStore } from '../../services/SessionStore';
import { UserSettingsApi } from '../../api/UserSettingsApi';

const sessionStoreKey = 'user-settings';


const getDefaultSettings = () => {
  const stateProps = {
    language: DefaultLanguage.Id,
    showCode: true,
    details:{}
  }
  const storedSettings = SessionStore.get( sessionStoreKey );
  let userSettings;
    
  if( !storedSettings ) {
    userSettings = stateProps;
  } else {
    for( const key in stateProps ) {
      if( !( key in storedSettings ) ) {
        //set default settings if stored settings are missing data
        userSettings = stateProps;
        break;
      }
    }
    userSettings = userSettings || storedSettings;
  }  
  return userSettings ;
}

const initialState = getDefaultSettings();

export const getUsersettings = createAsyncThunk( 'userSettings/getUsersettings', async ( action:{ token: string } )=>{
  return UserSettingsApi.getUsersettings( action );
} );

export const updateUsersettings = createAsyncThunk( 'userSettings/updateUsersettings', async ( action:{ token: string, request: any } )=>{
  return UserSettingsApi.updateUsersettings( action );
} );

//Gets User logged in details
export const getUserDetails = createAsyncThunk( 'userSettings/getUserDetails', async ( action:{ token: string } )=>{
  return UserSettingsApi.getUserDetails( action );
} );


const userSettingsSlice = createSlice( {
  name: 'userSettings',
  initialState,
  reducers: {
    setUserSettings( state, action ) {
      if( Object.keys( action.payload ).includes( 'showCode' ) ) {
        state.showCode = action.payload.showCode
      } else if( action.payload?.language ) {
        state.language = action.payload.language
      }
    },
    resetUserSettings( ) {
      return initialState;
    }
  },
  extraReducers: ( builder ) =>{
    builder.addCase( getUsersettings.fulfilled, ( state, action ) => {
      if( action.payload.data ) {
        return {...state, ...action.payload.data}
      }
    } );
    builder.addCase( getUserDetails.fulfilled, ( state, action ) => {
      if( action.payload.data ) {
        state.details = action.payload.data
      }
    } );
    builder.addCase( updateUsersettings.fulfilled, ( state, action ) => {
      if( action.payload.data ) {
        state.language = action.payload.data.language
        state.showCode = action.payload.data.showCode
      }
    } );
  }
} )

export const { setUserSettings, resetUserSettings } = userSettingsSlice.actions;


export default userSettingsSlice.reducer

