import React, { useEffect, useRef, useState } from 'react';
import { IconButton, Tooltip, Box, Paper, Badge, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Popper, ClickAwayListener } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../store';
import { EMessagesType, PopperModifier } from '../data/Constants';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { clearMessages, deleteMessage, setOpenPopoverOnLoad } from '../store/states/MessagesSlice';
import { useTranslation } from 'react-i18next';

export const MessagesPopover = () => {
  const { t } = useTranslation();

  const [arrowRef, setArrowRef] = React.useState( null );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>( null );

  const popoverMessagesState = useSelector( ( state:AppState )=> state.messagesState );

  const buttonRef = useRef( null );
  const dispatch = useDispatch<AppDispatch>();
  const open = Boolean( anchorEl );
  const popoverId = open ? 'simple-popover' : undefined;

  const handleClickOpen = ( event: React.MouseEvent<HTMLButtonElement> ) => {
    setAnchorEl( event.currentTarget );
  };

  const handleClose = () => {
    setAnchorEl( null );
    dispatch( setOpenPopoverOnLoad( false ) );
  };

  useEffect( ()=>{
    if( popoverMessagesState.openPopoverOnLoad ) { //To open the popover on load of configuration
      buttonRef.current && buttonRef.current.click();
    }
  },[popoverMessagesState.openPopoverOnLoad] )

  return <>
    <IconButton ref={ buttonRef } className = { `messagesIcon action-panel-buttons border-radius-0 icon-font-size ${anchorEl ? 'activeIcon' : ''} ${ popoverMessagesState.messages.length > 0 ? 'orangeBadge' : 'grayBadge' }` } aria-describedby={ popoverId } onClick={ handleClickOpen }>
      <Badge className="messageBadge" badgeContent={ popoverMessagesState.messages.length } color="primary" max={ 99 } showZero >
        <LightbulbIcon/>
      </Badge>
    </IconButton>
    <Popper
      id={ popoverId }
      open={ open }
      anchorEl={ anchorEl }
      placement="bottom"
      role="tooltip"
      className="zindex-100 popoverPaper"
      modifiers={ [
        ...PopperModifier,
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef,
          }
        }
      ] }
      sx={ {
        width: '30%',
        maxHeight: '50% !important'
      } }
    >
      <Box component="div" id="arrow" data-popper-arrow className="popperStyle arrow" ref={ setArrowRef } />
      <Box>
        <Paper>
          <ClickAwayListener onClickAway={ handleClose }>
            <TableContainer component={ Paper }>
              <Table sx={ { width: '100%', height: '100%' } } className="messagesTable" aria-label="messagesTable" size={ popoverMessagesState.messages.length > 0 ? 'small' : 'medium' } stickyHeader >
                <colgroup>
                  <col style={ { width:'5%' } }/>
                  <col style={ { width:'90%', overflowWrap: 'break-word'} }/>
                  <col style={ { width:'5%' } }/>
                </colgroup>

                <TableHead>
                  <TableCell/>
                  <TableCell sx={ {textAlign:'center',fontWeight:'bold'} }> { t( 'labels.messages' ) } </TableCell>
                  <TableCell>
                    {popoverMessagesState.messages.length > 0 && <Tooltip title={ t( 'tooltip.clearAll' ) }>
                      <IconButton onClick={ ()=>dispatch( clearMessages() ) }>
                        <DeleteForeverIcon color="error" />
                      </IconButton>
                    </Tooltip>}
                  </TableCell>
                </TableHead>

                <TableBody>
                  {popoverMessagesState.messages.length > 0 ? popoverMessagesState.messages.map( ( message,index )=>{
                    return <TableRow key={ index }>
                      <TableCell>{message.type === EMessagesType.Warning ? <WarningIcon color="warning" /> : null}</TableCell>
                      <TableCell>{message.text}</TableCell>
                      <TableCell>
                        <Tooltip title={ t( 'tooltip.delete' ) }>
                          <IconButton onClick={ ()=> dispatch( deleteMessage( index ) ) }>
                            <CloseIcon color="error" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  } ) :
                    <TableRow>
                      <TableCell />
                      <TableCell sx={ {textAlign:'center'} }>{ t( 'errorMessages.messagesUnavailable' ) }</TableCell>
                      <TableCell />
                    </TableRow>
                  }
                </TableBody>

              </Table>
            </TableContainer>
          </ClickAwayListener>
        </Paper>
      </Box>
    </Popper>
  </>

}