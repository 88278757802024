import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../store';
import { IsAppOpenedInIframe,IsAppOpenedByExtApp } from '../services';
import { setAuthState } from '../store/states/ConfigurationSlice';

/**
 * Renders an error message for unauthorized users
 * @returns {JSX.Element} the error message component
 */
export const Unauthenticated = () => {
  const {t} = useTranslation()
  const [showMessage,setShowMessage] = useState( false );
  const [timer,setTimer] = useState<NodeJS.Timer>()
  const dispatch = useDispatch<AppDispatch>();
  const configuration = useSelector( ( state: AppState )=> state.configuration )

  useEffect( ()=>{
    if( configuration.authState !== 'logout' ) {
      showMessage && setShowMessage( false );
      setTimer( setTimeout( ()=>setShowMessage( true ),5000 ) );
    }else{
      !showMessage && setShowMessage( true );
    }
    return ()=>{
      timer && clearTimeout( timer );
      dispatch( setAuthState( {authState: 'initial'} ) )
    }
  },[configuration.authState] )
  if( IsAppOpenedByExtApp() || IsAppOpenedInIframe() ) {
    return <h4>{t( 'errorMessages.general.unauthenticated' )} </h4> ;
  }
  return (
    showMessage && <h4>
      {t( 'errorMessages.unauthenticated' )}
    </h4> || null
  );
};