import { createSlice } from '@reduxjs/toolkit';
import { IError } from '../../../types';

const initialState:IError = {
  code:null,
  message:null,
  page:null,
  data:{}
}

const errorSlice = createSlice( {
  name: 'error',
  initialState,
  reducers: {
    setError( state, action ) {
      return action.payload; 
    },
    resetError() {
      return initialState; 
    },
  },
} )

export const { setError,resetError } = errorSlice.actions

export default errorSlice.reducer