
import { Box, Grid } from '@mui/material';
import React, { useState,useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getImages } from '../services/ConfigurationDataHelperFunctions';
import { IMaterialImages } from '../../types';

export const ImageGallery = ( {variable}:any ) => {
  
  const images = getImages( variable );
  
  const [slideNumber, setSlideNumber] = useState( 0 );
 
  const handleOpenImage = ( index:number ) =>{
    setSlideNumber( index )
  }
  useEffect( () => {
    const defaultImageIndex = images ? images.findIndex( ( i:IMaterialImages )=> i.isDefault ) : 0;
    setSlideNumber( defaultImageIndex && defaultImageIndex > -1 ? defaultImageIndex : 0 );
  },[] );
  const sliderSettings = {
    dots: true,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 5
  }

  return <>{images && images.length > 0 ? <Grid container sx={ {flexDirection: 'column'} } className="imageGrid">
    <Box className="fullScreenImage">
      <img src={ images[slideNumber].url }/>
    </Box>
    {images.length > 1 && <Box className="slider-wrapper"> 
      <Slider { ...sliderSettings }>
        { images.map( ( slide:IMaterialImages, index:number )=>
          <div key={ index } className="slick-slide" onClick={ ()=>handleOpenImage( index ) }>
            <img src={ slide.url }/>
          </div>
        )}
      </Slider>
    </Box>}
  </Grid> : <Grid container sx={ {flexDirection: 'column'} } className="noImageGrid"> 
    <Box className="text-center h-100 ">
      <img src="../../public/png/ProductImageNotFound.png" style={ {height:'inherit'} } />
    </Box>
  </Grid> }
    
  </>
};