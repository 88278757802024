import {
  MenuItem,
  Tooltip,
  IconButton,
  Paper,
  Popper,
  MenuList,
  ClickAwayListener,
  Box
} from '@mui/material';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, AppDispatch } from '../store';
import { getCurrencyCode, getSymbol } from '../services';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { PopperModifier, ProductScope } from '../data/Constants'
import { useTranslation } from 'react-i18next';
import { IAssignment } from '../../types';
import { AppAuthContext } from '../services/Contexts';
import { IsDefaultFlowInSessionStore } from '../services/SessionStoreHelperFunctions';
import { isFeatureEditable, isFeatureVisible } from '../services/ClaimsHelper';
import { isValidCurrency } from '../services/Price';
import { setCurrencyCode } from '../store/states/ApplicationSettingsSlice';
import { getPrice, resetModelPrice } from '../store/states/ConfigurationSlice';


const CurrencyDisplay = ( {t,availableCurrencies,isCurrencyEditable,anchorEl,handleClick,id,currencyCode,countryCode}:any )=>{
  const isCurrencyValid = isValidCurrency( currencyCode );
  return <Tooltip title={ t( 'labels.currency' ) }><span>
    <IconButton disabled={ !isCurrencyEditable || availableCurrencies.length <= 1 || !IsDefaultFlowInSessionStore() } className= { `currencyContainer languageContainer  pt-2 ${anchorEl ? 'activeIcon' : ''}` } onClick={ handleClick } aria-describedby={ id } data-testid="btn-currency"> {/* To disable the dropdown when it has only one currency or Application is loaded in a Down-Stream Application */}
      <span className= { !isCurrencyValid ? 'invalidCurrency' : 'symbol' }>{getSymbol( countryCode,currencyCode )}&nbsp; </span><span className={ !isCurrencyValid ? 'invalidCurrency' : '' }> ({currencyCode}) </span>
      <KeyboardArrowDownIcon />
    </IconButton>
  </span>
  </Tooltip>
}

/**
     * Renders the Currency dropdown where the user can change the currency
     * @returns {JSX.Element} the currency handler Element
     */
export const CurrencySetting = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const token = useContext( AppAuthContext );

  const claimsData = useSelector( ( state: AppState )=> state.claimsData )
  const configuration = useSelector( ( state: AppState )=> state.configuration )
  
  if( !configuration ) {
    return null;
  }
  
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>( null );
  const open = Boolean( anchorEl );
  const id = open ? 'scroll-playground' : undefined;
  const [arrowRef, setArrowRef] = React.useState( null );

  //selector
  const applicationSettings = useSelector( ( state:AppState )=>state.applicationSettings );

  const currencyList = configuration?.currencyList || [];
  const countryCode = configuration?.assignments?.find( ( a:IAssignment )=> a.variableId === ProductScope.MarketId )?.value || applicationSettings.currency?.countryCode || 'US';
  const currencyCode = applicationSettings.currency?.currencyCode || getCurrencyCode( applicationSettings.priceListMap,{currency:currencyList} ) ;
  const isCurrencyVisible = isFeatureVisible( claimsData.featureFlags.Currency );
  const isCurrencyEditable = isFeatureEditable( claimsData.featureFlags.Currency );

    
  const handleClick = ( event: React.MouseEvent<HTMLElement> ) => {
    setAnchorEl( anchorEl ? null : event.currentTarget );
  };
  const handleClose = ( ) => {
    setAnchorEl( null )
  }
  
  const onHandleCurrency = async ( value: string ) => {
    await dispatch( resetModelPrice() );
    const curr = availableCurrencies.find(
      ( _: string ) => _ === value
    );
    dispatch( setCurrencyCode( {currencyCode: curr} ) )
    handleClose();
    if( isValidCurrency( curr ) ) {
      await dispatch( getPrice( {token: token, countryCode: applicationSettings.priceListMap, currencyCode: value, modelId: configuration.savedConfiguration.modelContext.rootModel.id, priceOnChange: true } ) );
    }
  };
  const availableCurrencies: string[] = [].concat( configuration.data && configuration?.currencyList
    ? configuration?.currencyList
    : currencyCode );

  if( !isCurrencyVisible ) {
    return null
  }
  
  return <>
    <CurrencyDisplay t ={ t } availableCurrencies ={ availableCurrencies } isCurrencyEditable={ isCurrencyEditable } anchorEl={ anchorEl } handleClick={ handleClick } id={ id } currencyCode={ currencyCode } countryCode ={ countryCode } />
    <Popper className="zindex-100" id={ id } open={ open } anchorEl={ anchorEl } placement="bottom" role="tooltip" modifiers={ [
      ...PopperModifier,
      {
        name: 'arrow',
        enabled: true,
        options: {
          element: arrowRef,
        }
      }
    ] }
    >
      <div role="tooltip">
        <Box component="div" id="arrow" data-popper-arrow className="popperStyle arrow" ref={ setArrowRef }/>
        <Box >
          <Paper >
            <ClickAwayListener onClickAway={ handleClose }>
              <MenuList 
                autoFocusItem={ open }
                id="language-menu"
                aria-labelledby="language-button"
                data-testid="menu-currency"
              >
                {availableCurrencies.map( ( value: string ) => 
                  value !== applicationSettings.currency.currencyCode ? <MenuItem
                    onClick={ () => onHandleCurrency( value ) }
                    value={ value }
                    key={ value }
                  >
                    {getSymbol( countryCode,value )} ({value})
                  </MenuItem> : ''
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
            
        </Box>
      </div>
    </Popper>
  </>
};
