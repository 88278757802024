import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMessagesState, IMessages } from '../../../types';

const initialMessagesState: IMessagesState = {
  messages: [],
  openPopoverOnLoad: false,
};

const messagesSlice = createSlice({
  name: 'messages',
  initialState: initialMessagesState,
  reducers: {
    setMessage: (state, action: PayloadAction<IMessages>) => {
      state.messages.push(action.payload);
    },
    deleteMessage: (state, action: PayloadAction<number>) => {
      state.messages.splice(action.payload, 1);
    },
    clearMessages: (state) => {
      state.messages = [];
      state.openPopoverOnLoad = false;
    },
    setOpenPopoverOnLoad: (state, action: PayloadAction<boolean>) => {
      state.openPopoverOnLoad = action.payload;
    },
  },
});

export const { setMessage, deleteMessage, clearMessages, setOpenPopoverOnLoad } = messagesSlice.actions;
export default messagesSlice.reducer;