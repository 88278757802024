import { Table, TableCell, TableBody, TableContainer, TableRow } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IConfigurationProperty, IOrderingInstructionsProps, IFeatureOrFamily, IUserSettings } from '../../types';
import { AppState } from '../store';
import { EConfigurationProperty } from '../data/Constants';

const getFeatureOrderingInstructions = ( variable:IFeatureOrFamily,userSettings:IUserSettings )=>{
  const featureOrderingInstructionsArr = [];

  if( Array.isArray( variable.values ) ) {
    for ( const val of variable.values ) {

      const text = val.properties?.find( ( property: IConfigurationProperty ) => property.id === EConfigurationProperty.OrderingInstructions );
      if ( text && text.value && text.value !== 'NULL' ) {
        const id = userSettings.showCode ? `${val?.name} (${val.value})` : val.name;
        featureOrderingInstructionsArr.push( { id: id, value: text.value , type: 'FE' } );
      }

    }
  }
  return featureOrderingInstructionsArr;
}
/**
   * Renders the short sales text popup.
   * @param {any} props the properties for the short sales text component
   * @returns {JSX.Element} the short sales text popup component
   */
export const OrderingInstructions = ( { variable }: IOrderingInstructionsProps ) => {
  const {t} = useTranslation();

  const userSettings = useSelector( ( state: AppState )=> state.userSettings )
  const configuration = useSelector( ( state: AppState )=> state.configuration )
  // condition to check ORI for PM & Submodel from Breadcrumbar
  if( variable?.hasORI ) {
    const orderingInstructionPM = configuration?.orderingInstructions[variable.id];
    return <><p>{orderingInstructionPM.value}</p></>
  }
  const getFeatureFamilyOrderingInstructions = () => {
    return variable.properties?.find( ( property:IConfigurationProperty ) => property.id === EConfigurationProperty.OrderingInstructions )?.value
      ? variable.properties?.find( ( property:IConfigurationProperty ) => property.id === EConfigurationProperty.OrderingInstructions )?.value : null
  }
  const orderingInstruction = getFeatureFamilyOrderingInstructions()?.toString();
  const featureFamilyOrderingInstructions = orderingInstruction === 'NULL' ? null : orderingInstruction;
  const featureOrderingInstructionsArr = getFeatureOrderingInstructions( variable,userSettings );

  return <>
    {featureOrderingInstructionsArr.length <= 0 && !featureFamilyOrderingInstructions ? <p className="noTextMessage">{t( 'orderingInstructions.noOrderingInstructions' )}</p> :
      <><p>{featureFamilyOrderingInstructions}</p>
        {featureOrderingInstructionsArr.length > 0 && <TableContainer className="table-container">
          <Table aria-label="Ordering Instruction Table">
            <TableBody>
              {featureOrderingInstructionsArr.map( ( row ) =>
                <TableRow key={ row.id }>
                  {row.id && <TableCell className="table-cell">{row.id}</TableCell>}
                  <TableCell className="table-cell" >{row.value}</TableCell>

                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer> 
        } 
      </>    
    }
  </>
};