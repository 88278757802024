import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState, AppStore } from '../store';
import { SectionSummary } from '.';
import { IConfigurationSummaryPanel, IExtendedConfigureResponseOptional, ISection, ISubmodelCountVariableMap, ISummaryAccordionProps } from '../../types';
import { getModelPathFromSection } from '../services';
import { useSearchParams } from 'react-router-dom';
import { EUrlParams } from '../data/Constants'


/**
 * Returns an object with key as model path and value as Submodel count variable for that model path
 * @param {ISectionPrice[]} sections to find count variables in
 * @param {string} model to keep track of path
 * @returns {ISubmodelCountVariableMap} a dictionary with model path as key and count variable as value
 */
export function getSubmodelCountVariableMap( ) {
  const res:ISubmodelCountVariableMap = {};
  const config = AppStore.getState().configuration as IExtendedConfigureResponseOptional;
  const countVariables = config?.contextData?.countVariables;
  countVariables && Object.entries( countVariables ).forEach( ( [k,v] )=>{
    if( !v ) {
      return;
    }
    const path = k.split( '.' ).map( ( p )=> {
      const match = p.match( /\d+/ )
      return match ? match[0] : ''
    } ).join( '.' );
    res[path] = v;
  } )
  return res;
}

/**
 * The Component that generates the accordions required for configuration summary
 * @param {IAccordionControllerProps} param0 props required for the component
 * @returns {JSx.Element} The Accordion Group controlled by this component
 */
export const SummaryAccordion = ( {scope, rootSection, getCountVariable, modelPath}:ISummaryAccordionProps )=>{
  const [accordionValue,setAccordionValue] = useState<string|null>( null );
  const changeAccordionValue = ( av:string ) => {
    if( accordionValue === av ) {
      setAccordionValue( null );
    } else{
      setAccordionValue( av );
    }
  }
  return <>
    <SectionSummary section={ scope } depth={ 0 } displayScope={ true } modelPath={ modelPath } accordionValue={ accordionValue } setAccordionValue={ changeAccordionValue }/>
    <SectionSummary section={ rootSection } depth={ 0 } modelPath={ modelPath } getCountVariable={ getCountVariable } accordionValue={ accordionValue } setAccordionValue={ changeAccordionValue }/>
  </>;
}

/**
 * Renders scope section and all sections with assignments
 * @param {IConfigurationSummaryPanel} props the properties for the configuration summary component
 * @returns {JSX.Element} the configuration summary component
 */
export const ConfigurationSummaryPanel = ( { rootSection,scope }: IConfigurationSummaryPanel ) => {
  const [serachParams] = useSearchParams();
  const configuration = useSelector( ( state: AppState )=> state.configuration )
  if( !configuration || !configuration.data ) {
    return null;
  }
  const model = serachParams.get( EUrlParams.Model ) || '';
  const modelPath = model ? [configuration.savedConfiguration.modelContext.rootModel.id,...model.split( '.' )] : [configuration.savedConfiguration.modelContext.rootModel.id];
  const submodelCountVariableMap = getSubmodelCountVariableMap();
  const getCountVariable = ( modelSection:ISection )=>{
    const modelId = getModelPathFromSection( modelSection );
    return modelId ? submodelCountVariableMap[modelId] : null;
  }

  return scope && <Box className={ 'configurationSummary-root root' }>
    <SummaryAccordion scope={ scope } rootSection={ rootSection } getCountVariable={ getCountVariable } modelPath={ modelPath }/>
  </Box> || null;
}