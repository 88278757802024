import AxiosClient from './AxiosClient';
import { appSettings } from '../settings';
import { getHeaders } from './HttpUtil';
import { handleError } from '../services/ConfigurationDataHelperFunctions';

export const UserSettingsApi = {
  getUsersettings: ( action: { token: string } ) => {
    return AxiosClient
      .get( '/user/v1/preferences/read',
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined 
      ).then( ( response ) => {
        return {
          data: response.data
        }
      } ).catch( () => null ) //If get User settings failed, default or fall back settings would be used
  },

  updateUsersettings: ( action: { token: string, request: any } ) => {
    return AxiosClient
      .post( '/user/v1/preferences/set',
        action.request,
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined 
      ).then( () => {
        return {
          data: action.request
        }
      } ).catch( ( err ) => handleError( err ) )
  },

  getUserDetails: ( action: { token: string } ) => {
    return AxiosClient
      .get( '/user/v1/details',
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined 
      ).then( ( response ) => {
        return {
          data: response.data
        }
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  }
}