import React from 'react';
import { Box} from '@mui/material';
import { IPriceProps } from '../../../types';
import { getFormattedPrice,getSymbol } from '../../services';
import { isFeatureVisible } from '../../services/ClaimsHelper';
import { isValidCurrency } from '../../services/Price';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';

export const Price = ( {price,isBundled}:IPriceProps ) => {
  const claimsData = useSelector( ( state: AppState )=> state.claimsData )
  const applicationSettings = useSelector( ( state: AppState )=> state.applicationSettings )

  const currencyCode = applicationSettings?.currency.currencyCode;
  if( price === null && !isBundled ) {
    return null
  }
  if( !isFeatureVisible( claimsData.featureFlags.ListPrice ) ) {
    return null
  }
  return <>
    <Box alignItems="center" justifyContent="center" >
      {currencyCode && <span className={ !isValidCurrency( currencyCode ) ? 'invalidCurrency' : 'content' }>
        { getSymbol( applicationSettings?.currency.countryCode, currencyCode ) } 
      </span>}
      {currencyCode && getFormattedPrice( price )}
    </Box>
  </>
}